#header-logo {
  margin-top: -5px;
}

.monospaced {
  font-family: "Droid Sans Mono", Consolas!important;
}

h3 {
  margin-top:5px;
  margin-bottom:5px;
}

.list-group-item-text { color: #3097D1; }